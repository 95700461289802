<template>
<div class="idea" v-loading="pageLoading">
  <div class="left">
    <h2 class="classify-title">全部分类</h2>
    <div class="classification">
        <ul class="list">
          <li
            class="list-item"
            v-for="(item, index) of typeList"
            :key="index"
            @click="changeType(item.id)"
            :class="{active:item.id==typeIndex}"
          >
            <span>
              {{ item.name }}
            </span>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </div>
    <h2 class="classify-title"><img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%83%AD%E9%97%A8%20%E6%8B%B7%E8%B4%9D.png"/>热门问答</h2>
    <div class="classify">
      <div class="classify-item" :class="{active: classify == index}" @click="getList(index)" v-for="(item,index) of ideaNavData" :key="item.id">
        {{item}}
      </div>
      <van-empty
          class="emptySmall"
          style="margin: 0 auto"
          v-if="!ideaNavData.length"
          description="暂无数据"
        />
    </div>
  </div>
  <div class="right">
    <div class="type">
      <div class="idea-nav-item" @click="getTag(1)" :class="{ active: tag == 1 }">最热锦囊</div>
      <div class="idea-nav-item" @click="getTag(2)" :class="{ active: tag == 2 }">最新锦囊</div>
    </div>
    <idea-list :list="list" v-if="list.length"/>
    <van-empty
          class="emtpyMain"
          v-else
          description="暂无数据"
        />
  </div>
</div>
</template>

<script>
import myAudio from '@/components/Audio'
import IdeaList from '../components/ideaList.vue';
export default {
  name: "Home",
  components:{ myAudio, IdeaList },
  data() {
    return {
      typeList:[],
      typeIndex: 0,
      isShowAudioPlayer:false,
      ideaNavData:[],
      pageLoading: true,
      list: [{},{},{}],
      allList:[],
      tag: 1,
      classify: 0,
      certificate: [],
      topData: [],
      category: [],
      
      more: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onLoad);
    
    this.getType();
  },
  destroyed() {
    window.removeEventListener('scroll', this.onLoad)
  },
  computed: {
    plat() {
      return this.$store.state.plat_id
    }
  },
  methods: {
    // 获取锦囊分类
    async getType(){
      this.pageLoading = true;
      let res = await this.$api({
          method: "get",
          url: "/api/silkbag/category/list",
          data: {
            goods_category_id: this.plat,
            page: 1,
            limit: 1000
          }
        });
        if(res.code == 0){
          this.typeList = res.data.category
        res.data.total_silk.forEach(item=>{
          item.updated_at = item.updated_at.slice(0,10)
          item.content = item.content.replace(/<\/?[^>]*>/g, "")
          item.content = item.content.length>100 ? item.content.slice(0,100)+'...' : item.content
        })
        this.allList = res.data.total_silk
        this.list = this.allList
        // this.getList()
      }else{
        this.$toast(res.msg)
      }
      this.pageLoading = false;
    },
    changeType(id){
      this.typeIndex = id
      if(this.typeIndex){
        this.getList()
      }else{
        this.getType()
      }
    },
    getTag(index) {
      this.tag = index;
      if(this.typeIndex){
        this.getList()
      }else{
        this.getType()
      }
    },
    async getList() {
      this.pageLoading = true;
      document.documentElement.scrollTop = 0;
      // 锦囊列表
      let res = await this.$api({
        method: 'get',
        url: "/api/silkbag/list",
        data:{
          sort:this.tag,
          category_id: this.typeIndex === 0 ? '' : this.typeIndex,
          page: 1,
          limit : 1000
        }
      });
      
      if (res.code == 0) {
    
        res.data.forEach(item=>{
          item.updated_at = item.updated_at.slice(0,10)
          item.content = item.content.replace(/<\/?[^>]*>/g, "")
          item.content = item.content.length>100 ? item.content.slice(0,100)+'...' : item.content
        })
        this.list = res.data
      } else {
        this.$toast(res.msg)
      }
      this.pageLoading = false;
    },
        
    getMore() {
      if (this.more) {
        return;
      }
      this.more = true
      if (this.menu == 2) {
        axios.get(`/api/platform/pc/category?flat_id=${this.plat}&page=${this.page}`, {})
          .then((res) => {
            this.more = false;
            console.log(res);
            if (res.code == 0) {
              let list = res.data.category[this.classify].goods
              this.list = this.list.concat(list);
              this.loading = false;
              if (list.length < 10) {
                this.finished = true
              } else {
                this.finished = false;
              }
            } else {
              this.$toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.more = false;
          });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    next()
  },

};
</script>

<style lang="scss" scoped>
.idea {
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  .van-sticky {
    left: auto;
    right: auto;
  }

  .left {
    margin-right: 22px;
    width: 262px;
    overflow: hidden;
    margin-top: 30px;
    background: #fff;
    border-radius: 10px;
    .classify-title{
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #333;
      padding-left: 20px;
      img{
        margin-right: 9px;
      }
    }
    .classification {
      
      margin-top: 16px;
      overflow: hidden;
      padding-bottom: 10px;
      border-bottom: 1px solid #F4F4F4;
      .list {
        padding: 8px 0;

        .list-item {
          position: relative;
          width: 100%;
          height: 37px;
          line-height: 37px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 16px;
          color: #333;
          font-size: 16px;
          box-sizing: border-box;

          &:hover{
            background: rgb(255, 107, 0, 0.05);
            color: rgb(164, 0, 1);
          }
          
          i{
            color: #C6C6C6;
          }
        }
        .active {
            background: rgb(255, 107, 0, 0.05);
            color: rgb(164, 0, 1);
        }
      }
    }
  }

  .classify {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    flex: 0 0 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;

    .classify-item {
      width: 50%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 22px;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img{
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    flex: 1;
    margin-top: 30px;
  }

  
  

  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;
    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 20px;
      color: #333;
      margin-left: 10px;
    }
  }

  .idea,
  .cert {

    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .idea-nav-item {
      width: calc((100% - 200px) / 7);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 30px;
      margin-right: 20px;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 8px 8px 0 0;
        box-sizing: border-box;
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }
}
.type {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 6px;
    }
.type-select_line {
      border-bottom: 1px solid #E4E5E6;
      width: 100%;
      margin: 0 auto 10px;
    }
</style>

<style lang="scss">

</style>
